import mock from '@/@fake-db/mock'
import { paginateArray, sortCompare } from '@/@fake-db/utils'

/* eslint-disable global-require */
const data = {
  roles: [
    {
      id: 1,
      name: 'admin',
      description: 'Main role, can manage all',
      permissions: [
        {
          action: 'manage',
          subject: 'all',
        },
      ],
      editable: false,
    },
    {
      id: 2,
      name: 'staff',
      description: 'staff can only read all modules excluding users/admins,',
      permissions: [
        {
          action: 'read',
          subject: 'dashboard',
        },
        {
          action: 'read',
          subject: 'transaction',
        },
        {
          action: 'read',
          subject: 'activity',
        },
        {
          action: 'read',
          subject: 'battery',
        },
        {
          action: 'read',
          subject: 'station',
        },
        {
          action: 'read',
          subject: 'clients',
        },
      ],
    },
  ],
}
/* eslint-enable */

// ------------------------------------------------
// GET: Return Roles role
// ------------------------------------------------
mock.onGet('/apps/role/roles').reply(config => {
  // eslint-disable-next-line object-curly-newline
  const {
    q = '',
    perPage = 10,
    page = 1,
    sortBy = 'id',
    sortDesc = false,
  } = config.params
  /* eslint-enable */

  const queryLowered = q.toLowerCase()
  const filteredData = data.roles.filter(
    role =>
      /* eslint-disable operator-linebreak, implicit-arrow-linebreak */
      (role.name.toLowerCase().includes(queryLowered)) ||
      (role.description.toLowerCase().includes(queryLowered)),
  )
  /* eslint-enable  */

  const sortedData = filteredData.sort(sortCompare(sortBy))
  if (sortDesc) sortedData.reverse()

  return [
    200,
    {
      roles: paginateArray(sortedData, perPage, page),
      total: filteredData.length,
    },
  ]
})

// ------------------------------------------------
// POST: Add new role
// ------------------------------------------------
mock.onPost('/apps/role/roles').reply(config => {
  // Get event from post data
  const { role } = JSON.parse(config.data)

  // Assign Status

  const { length } = data.roles
  let lastIndex = 0
  if (length) {
    lastIndex = data.roles[length - 1].id
  }
  role.id = lastIndex + 1

  data.roles.push(role)

  return [201, { role }]
})

// ------------------------------------------------
// GET: Return Single Role
// ------------------------------------------------
mock.onGet(/\/apps\/role\/roles\/\d+/).reply(config => {
  // Get event id from URL
  let roleId = config.url.substring(config.url.lastIndexOf('/') + 1)

  // Convert Id to number
  roleId = Number(roleId)

  const roleIndex = data.roles.findIndex(e => e.id === roleId)
  const role = data.roles[roleIndex]

  if (role) return [200, role]
  return [404]
})
