export default [
  {
    path: '/',
    name: 'dashboard',
    component: () => import('@/views/dashboard/Dashboard.vue'),
    meta: {
      resource: 'dashboard',
      action: 'read',
    },
  },
  {
    path: '/dashboard/report',
    name: 'dashboard-report',
    component: () =>
      // eslint-disable-next-line implicit-arrow-linebreak
      import('@/views/dashboard/reports/reports-view/ReportsView.vue'),
    meta: {
      resource: 'dashboard',
      action: 'read',
    },
  },
]
