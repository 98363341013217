/* eslint-disable implicit-arrow-linebreak */
export default [
  {
    path: '/user/list',
    name: 'apps-users-list',
    component: () => import('@/views/apps/user/users-list/UsersList.vue'),
    meta: {
      resource: 'user',
      action: 'read',
    },
  },
  {
    path: '/user/view/:id',
    name: 'apps-users-view',
    component: () => import('@/views/apps/user/users-view/UsersView.vue'),
    meta: {
      resource: 'user',
      action: 'read',
    },
  },
  {
    path: '/user/edit/:id',
    name: 'apps-users-edit',
    component: () => import('@/views/apps/user/users-edit/UsersEdit.vue'),
    meta: {
      resource: 'user',
      action: 'write',
    },
  },
  // roles
  {
    path: '/user/role',
    name: 'apps-roles-list',
    component: () => import('@/views/apps/role/roles-list/RolesList.vue'),
    meta: {
      resource: 'role',
      action: 'read',
    },
  },
  {
    path: '/user/role/edit/:id',
    name: 'apps-roles-edit',
    component: () => import('@/views/apps/role/roles-edit/RolesEdit.vue'),
    meta: {
      resource: 'role',
      action: 'write',
    },
  },
  {
    path: '/user/role/add',
    name: 'apps-roles-add',
    component: () => import('@/views/apps/role/roles-add/RolesAdd.vue'),
    meta: {
      resource: 'role',
      action: 'create',
    },
  },
  // clients
  {
    path: '/client/list',
    name: 'apps-clients-list',
    component: () => import('@/views/apps/client/clients-list/ClientsList.vue'),
    meta: {
      resource: 'client',
      action: 'read',
    },
  },
  {
    path: '/client/view/:id',
    name: 'apps-clients-view',
    component: () => import('@/views/apps/client/clients-view/ClientsView.vue'),
    meta: {
      resource: 'client',
      action: 'read',
    },
  },
  {
    path: '/client/edit/:id',
    name: 'apps-clients-edit',
    component: () => import('@/views/apps/client/clients-edit/ClientsEdit.vue'),
    meta: {
      resource: 'client',
      action: 'write',
    },
  },

  // product
  {
    path: '/product/list',
    name: 'apps-product-list',
    component: () =>
      import('@/views/apps/product/product-list/ProductList.vue'),
    meta: {
      resource: 'product',
      action: 'read',
    },
  },
  {
    path: '/product/view/:id',
    name: 'apps-product-view',
    component: () =>
      import('@/views/apps/product/product-view/ProductView.vue'),
    meta: {
      resource: 'product',
      action: 'read',
    },
  },
  {
    path: '/product/edit/:id',
    name: 'apps-product-edit',
    component: () =>
      import('@/views/apps/product/product-edit/ProductEdit.vue'),
    meta: {
      resource: 'product',
      action: 'write',
    },
  },

  // car
  {
    path: '/car/list',
    name: 'apps-car-list',
    component: () => import('@/views/apps/car/car-list/CarList.vue'),
    meta: {
      resource: 'car',
      action: 'read',
    },
  },
  {
    path: '/car/view/:id',
    name: 'apps-car-view',
    component: () => import('@/views/apps/car/car-view/CarView.vue'),
    meta: {
      resource: 'car',
      action: 'read',
    },
  },

  // kassas
  {
    path: '/kassa/list',
    name: 'apps-kassa-list',
    component: () => import('@/views/apps/kassas/kassas-list/KassasList.vue'),
    meta: {
      resource: 'kassa',
      action: 'read',
    },
  },
  {
    path: '/kassa/preview/:id',
    name: 'apps-kassa-preview',
    component: () =>
      import('@/views/apps/kassas/kassas-preview/KassasPreview.vue'),
    meta: {
      resource: 'kassa',
      action: 'read',
    },
    redirect: { name: 'misc-coming-soon' },
  },

  // activity
  // {
  //   path: '/activities',
  //   name: 'activities',
  //   component: () => import('@/views/apps/activity/activity-list/ActivityList.vue'),
  //   meta: {
  //     resource: 'activity',
  //     action: 'read',
  //   },
  // },
  // {
  //   path: '/activity/:id',
  //   name: 'activity',
  //   component: () => import('@/views/apps/activity/activity-preview/ActivityPreview.vue'),
  //   redirect: { name: 'misc-coming-soon' },
  //   meta: {
  //     resource: 'activity',
  //     action: 'read',
  //   },
  // },

  // devices
  // {
  //   path: '/batteries/:id?',
  //   name: 'batteries',
  //   component: () => import('@/views/apps/batteries/battery-list/BatteryList.vue'),
  //   meta: {
  //     resource: 'batteries',
  //     action: 'read',
  //   },
  // },

  // stations
  // {
  //   path: '/stations/:id?',
  //   name: 'stations',
  //   component: () => import('@/views/apps/stations/station-list/StationList.vue'),
  //   meta: {
  //     resource: 'stations',
  //     action: 'read',
  //   },
  // },
  // {
  //   path: '/stations/view/:id',
  //   name: 'apps-stations-view',
  //   component: () => import('@/views/apps/stations/station-view/StationView.vue'),
  //   meta: {
  //     resource: 'stations',
  //     action: 'read',
  //   },
  // },

  // Purchase
  {
    path: '/purchase/list',
    name: 'apps-purchase-list',
    component: () =>
      import('@/views/apps/purchase/purchase-list/PurchaseList.vue'),
    meta: {
      resource: 'purchase',
      action: 'read',
    },
  },
  {
    path: '/purchase/preview/:id',
    name: 'apps-purchase-preview',
    component: () =>
      import('@/views/apps/purchase/purchase-preview/PurchasePreview.vue'),
    meta: {
      resource: 'purchase',
      action: 'read',
    },
  },
  {
    path: '/purchase/add/',
    name: 'apps-purchase-add',
    component: () =>
      import('@/views/apps/purchase/purchase-add/PurchaseAdd.vue'),
    meta: {
      resource: 'purchase',
      action: 'create',
    },
  },
  {
    path: '/purchase/add/from/supplier',
    name: 'apps-purchase-add-shipment',
    component: () =>
      import(
        '@/views/apps/purchase/purchase-add-shipment/PurchaseAddShipment.vue'
      ),
    meta: {
      resource: 'purchase',
      action: 'create',
    },
  },
  {
    path: '/purchase/edit/:id',
    name: 'apps-purchase-edit',
    component: () =>
      import('@/views/apps/purchase/purchase-edit/PurchaseEdit.vue'),
    meta: {
      resource: 'purchase',
      action: 'write',
    },
  },

  // Sales
  {
    path: '/sales/list',
    name: 'apps-sales-list',
    component: () => import('@/views/apps/sales/sales-list/SalesList.vue'),
    meta: {
      resource: 'sale',
      action: 'read',
    },
  },
  {
    path: '/sales/preview/:id',
    name: 'apps-sales-preview',
    component: () =>
      import('@/views/apps/sales/sales-preview/SalesPreview.vue'),
    meta: {
      resource: 'sale',
      action: 'read',
    },
  },
  {
    path: '/sales/add/:tablename?/:id?',
    name: 'apps-sales-add',
    component: () => import('@/views/apps/sales/sales-add/SalesAdd.vue'),
    meta: {
      resource: 'sale',
      action: 'create',
    },
  },
  {
    path: '/sales/edit/:id',
    name: 'apps-sales-edit',
    component: () => import('@/views/apps/sales/sales-edit/SalesEdit.vue'),
    meta: {
      resource: 'sale',
      action: 'write',
    },
  },

  // transaction
  {
    path: '/transaction/list',
    name: 'apps-transaction-list',
    component: () => import('@/views/apps/transaction/TransactionMain.vue'),
    meta: {
      resource: 'transaction',
      action: 'read',
    },
  },
  {
    path: '/transaction/preview/:id',
    name: 'apps-transaction-preview',
    component: () =>
      import(
        '@/views/apps/transaction/transaction-preview/TransactionPreview.vue'
      ),
    meta: {
      resource: 'transaction',
      action: 'read',
    },
  },
  {
    path: '/settle/preview/:id',
    name: 'apps-settle-preview',
    component: () =>
      import('@/views/apps/transaction/transaction-preview/SettlePreview.vue'),
    meta: {
      resource: 'transaction',
      action: 'read',
    },
  },
  {
    // path: '/transaction/add/:activity?/:related_to?',
    path: '/transaction/add/:id?',
    name: 'apps-transaction-add',
    component: () =>
      import('@/views/apps/transaction/transaction-add/TransactionAdd.vue'),
    meta: {
      resource: 'transaction',
      action: 'create',
    },
  },
  {
    path: '/transaction/edit/:id',
    name: 'apps-transaction-edit',
    component: () =>
      import('@/views/apps/transaction/transaction-edit/TransactionEdit.vue'),
    meta: {
      resource: 'transaction',
      action: 'write',
    },
  },

  // Warehouse
  {
    path: '/warehouse/management',
    name: 'apps-warehouse-management',
    component: () => import('@/views/apps/warehouse/WarehouseManagement.vue'),
    meta: {
      resource: 'warehouse',
      action: 'read',
    },
  },
  {
    path: '/warehouse/list',
    name: 'apps-warehouse-list',
    component: () =>
      import('@/views/apps/warehouse/warehouse-list/WarehouseList.vue'),
    meta: {
      resource: 'warehouse',
      action: 'read',
    },
  },
  {
    path: '/warehouse/view/:id',
    name: 'apps-warehouse-view',
    component: () =>
      import('@/views/apps/warehouse/warehouse-view/WarehouseView.vue'),
    meta: {
      resource: 'warehouse',
      action: 'read',
    },
  },
]
