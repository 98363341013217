import mock from '@/@fake-db/mock'
import { paginateArray, sortCompare } from '@/@fake-db/utils'

/* eslint-disable global-require */
const data = {
  batteries: [
    {
      id: 54987,
      date: '13 Dec 2019',
      station: {
        id: '4987',
        location: 'USA',
      },
      status: 'Используется',
      workable: 'Исправен',
    },
    {
      id: 49388,
      date: '17 Jul 2019',
      station: {
        id: '4988',
        location: 'Haiti',
      },
      status: 'В ожидании',
      workable: 'Исправен',
    },
    {
      id: 49893,
      date: '19 Oct 2019',
      station: {
        id: '5345',
        location: 'Denmark',
      },
      status: 'В ожидании',
      workable: 'Исправен',
    },
    {
      id: 49903,
      date: '06 Mar 2020',
      station: {
        id: '4997',
        location: 'Cambodia',
      },
      status: 'Не активный',
      workable: 'Не работает',
    },
  ],
}
/* eslint-enable */

// ------------------------------------------------
// GET: Return Users
// ------------------------------------------------
mock.onGet('/apps/battery/batteries').reply(config => {
  // eslint-disable-next-line object-curly-newline
  const { q = '', perPage = 10, page = 1, sortBy = 'id', sortDesc = false, status = null } = config.params
  /* eslint-enable */

  const queryLowered = q.toLowerCase()
  const filteredData = data.batteries.filter(
    battery =>
      /* eslint-disable operator-linebreak, implicit-arrow-linebreak */
      (battery.station.id.toLowerCase().includes(queryLowered) ||
        battery.station.location.toLowerCase().includes(queryLowered)) &&
      battery.status === (status || battery.status),
  )
  /* eslint-enable  */

  const sortedData = filteredData.sort(sortCompare(sortBy))
  if (sortDesc) sortedData.reverse()

  return [
    200,
    {
      batteries: paginateArray(sortedData, perPage, page),
      total: filteredData.length,
    },
  ]
})

// ------------------------------------------------
// GET: Return Single battery
// ------------------------------------------------
mock.onGet(/\/apps\/battery\/batteries\/\d+/).reply(config => {
  // Get event id from URL
  let batteryId = config.url.substring(config.url.lastIndexOf('/') + 1)

  // Convert Id to number
  batteryId = Number(batteryId)

  const batteryIndex = data.batteries.findIndex(e => e.id === batteryId)
  const battery = data.batteries[batteryIndex]
  const responseData = {
    battery,
  }

  if (battery) return [200, responseData]
  return [404]
})

// ------------------------------------------------
// GET: Return Clients
// ------------------------------------------------
mock.onGet('/apps/battery/stations').reply(() => {
  const stations = data.batteries.map(battery => ({ label: battery.station.id, value: battery.station }))
  return [200, stations]
})
