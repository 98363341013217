import mock from '@/@fake-db/mock'
import { paginateArray, sortCompare } from '@/@fake-db/utils'

/* eslint-disable global-require */
const data = {
  stations: [
    {
      id: 54987,
      date: '13 Dec 2019',
      batteries: [
        {
          id: '4987',
          location: 'USA',
        },
        {
          id: '4988',
          location: 'USA',
        },
        {
          id: '4989',
          location: 'USA',
        },
        {
          id: '4990',
          location: 'USA',
        },
      ],
      status: 'Используется',
      workable: 'Исправен',
    },
    {
      id: 49388,
      date: '17 Jul 2019',
      batteries: [
        {
          id: '4991',
          location: 'USA',
        },
        {
          id: '4992',
          location: 'USA',
        },
        {
          id: '4993',
          location: 'USA',
        },
        {
          id: '4994',
          location: 'USA',
        },
      ],
      status: 'В ожидании',
      workable: 'Исправен',
    },
    {
      id: 49893,
      date: '19 Oct 2019',
      batteries: [
        {
          id: '4995',
          location: 'USA',
        },
        {
          id: '4996',
          location: 'USA',
        },
        {
          id: '4997',
          location: 'USA',
        },
        {
          id: '4998',
          location: 'USA',
        },
      ],
      status: 'В ожидании',
      workable: 'Исправен',
    },
    {
      id: 49903,
      date: '06 Mar 2020',
      batteries: [
        {
          id: '4999',
          location: 'USA',
        },
        {
          id: '5000',
          location: 'USA',
        },
        {
          id: '5001',
          location: 'USA',
        },
        {
          id: '5002',
          location: 'USA',
        },
      ],
      status: 'Не активный',
      workable: 'Не работает',
    },
  ],
}
/* eslint-enable */

// ------------------------------------------------
// GET: Return Users
// ------------------------------------------------
mock.onGet('/apps/station/stations').reply(config => {
  // eslint-disable-next-line object-curly-newline
  const { q = '', perPage = 10, page = 1, sortBy = 'id', sortDesc = false, status = null } = config.params
  /* eslint-enable */

  const queryLowered = q.toLowerCase()
  const filteredData = data.stations.filter(
    station =>
      /* eslint-disable operator-linebreak, implicit-arrow-linebreak */
      (station.date.toLowerCase().includes(queryLowered) ||
        station.workable.toLowerCase().includes(queryLowered)) &&
      station.status === (status || station.status),
  )
  /* eslint-enable  */

  const sortedData = filteredData.sort(sortCompare(sortBy))
  if (sortDesc) sortedData.reverse()

  return [
    200,
    {
      stations: paginateArray(sortedData, perPage, page),
      total: filteredData.length,
    },
  ]
})

// ------------------------------------------------
// GET: Return Single station
// ------------------------------------------------
mock.onGet(/\/apps\/station\/stations\/\d+/).reply(config => {
  // Get event id from URL
  let stationId = config.url.substring(config.url.lastIndexOf('/') + 1)

  // Convert Id to number
  stationId = Number(stationId)

  const stationIndex = data.stations.findIndex(e => e.id === stationId)
  const station = data.stations[stationIndex]
  const responseData = {
    station,
  }

  if (station) return [200, responseData]
  return [404]
})

// ------------------------------------------------
// GET: Return Clients
// ------------------------------------------------
mock.onGet('/apps/station/stationss').reply(() => {
  const stations = data.stations.map(station => ({ label: station.batteries.id, value: station.batteries }))
  return [200, stations]
})
